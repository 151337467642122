<template>
<div class="mt-2"> 
  
  <b-row no-gutters cols="12">
    
    <b-col cols="4" class="pr-1">
      <b-card body-class="p-0 notifications-card-height position-relative d-flex flex-direction-column">

          <div class="p-1 notification-list-header">
            <div>
              <h2 align="center" class="position-relative mb-2 text-success font-weight-bolder w-fit-content mx-auto">
                Notifications
                <div class="notifications-number-sign" v-if="getLoggedUser && getLoggedUser.notification_number > 0">
                  {{getLoggedUser.notification_number}} new 
                </div>
              </h2>
            </div>          

            <div>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input placeholder="Search" type="search" v-model="searchTerm" @change="searchNotifications()" debounce="1000"/>
              </b-input-group>
            </div>
            <!-- <div class="text-secondary mt-50 ml-25">
              
            </div> -->
          </div>
          
          <div class="notification-list-container">

            <template v-if="Array.isArray(notifications)">

              <div v-if="notifications.length == 0">
                <h3 align="center" class="text-secondary mt-3"> No Notifications </h3>
              </div>

              <!-- ====== NOTIFICATION ITEM ====== -->
              <div 
                class="d-flex justify-content-between align-items-center w-100 notification-list-item" 
                tabindex="0" 
                v-for="notification in notifications"
                :key="'notification-list-item-'+notification.id"
                :class="{
                    'is-shown': !!selectedNotification && notification.id == selectedNotification.id,
                    'was-viewed': notification.visualization == true
                    }"
                @click="selectNotification(notification)"
              >

                <div class="d-flex justify-content-start align-items-center">

                  <div class="mr-75">

                    <span class="notification-alert-icon danger" v-if="notification.enum_type == 'CRITICAL_ERROR'">
                      <b-icon icon="exclamation-triangle" scale="1.3" shift-v="1" shift-h="0.5"/>
                    </span>

                    <span class="notification-alert-icon warning" v-else>
                      <b-icon icon="exclamation-octagon" scale="1.3"/>
                    </span>

                  </div>

                  <div>
                    <div class="font-weight-bolder">{{notification.transmission_name}}</div>
                    <div class="small text-secondary">{{notification.organization_name}}</div>
                  </div>

                </div>
                
                <div align="right" class="small">
                  <div>
                    {{moment(notification.created_at).format('DD/MM HH:mm a')}}
                  </div>
                  <div v-if="notification.visualization == true">
                    viewed
                    <b-icon icon="check-all" variant="info"/>
                  </div>
                  <div v-else>
                    <br>
                  </div>
                </div>
              </div>
              <!-- ====== NOTIFICATION ITEM ====== -->

              <div v-if="getMeta.isLoading" align="center" class="py-2">
                <b-spinner small/>
              </div>

            </template>
            
            <template v-else>
              <b-skeleton height="55px" v-for="i in 6" :key="'skeleton-list-notification-'+i" class="mx-1" animation="fade"/>
            </template>

            
            <div 
              v-observe-visibility="reachedListEnd"
            >
              <span></span>
            </div>

          </div>
          
      </b-card>
    </b-col>
    
    <b-col cols="8" class="pl-1">
       <b-card body-class="notifications-card-height d-flex flex-direction-column justify-content-between">
          <template v-if="selectedNotification">
            <div>

              <div class="d-flex align-items-center justify-content-between mt-1  ">
                <div class="d-flex">
                  <div class="mr-2">
                  
                    <span class="notification-alert-icon large danger" v-if="selectedNotification.enum_type == 'CRITICAL_ERROR'">
                      <b-icon icon="exclamation-triangle" scale="1.3" shift-v="2"/>
                    </span>

                    <span class="notification-alert-icon large warning" v-else>
                      <b-icon icon="exclamation-octagon" scale="1.3"/>
                    </span>

                  </div>
                  <div>
                    <h3 
                      class="m-0 font-weight-bolder" 
                      :class="{
                        'text-danger': selectedNotification.enum_type == 'CRITICAL_ERROR',
                        'text-warning': selectedNotification.enum_type == 'GENERAL_ERROR'
                      }"
                    >
                      <span v-if="selectedNotification.enum_type == 'CRITICAL_ERROR'">
                        Critical
                      </span>
                       Error on {{selectedNotification.transmission_name}}
                    </h3>
                    <h4 class="font-weight-bolder text-reset">{{moment(selectedNotification.created_at).calendar()}}</h4>
                  </div>
                </div>

                <div>
                  <span>
                    viewed
                    <b-icon icon="check2-all" variant="info"/>
                  </span>
                </div>
              </div>
              
              <div align="center" class="mt-5 px-5">
                <div class="font-weight-bold">
                  <b>Error Message</b> <b-icon icon="arrow-right-short"/> <span>{{selectedNotification.message}}</span>
                </div>
                
                <h4 class="w-fit-content mt-5 text-left">
                  <div class="mb-50">
                    Organization <b-icon-arrow-right-short variant="success"/> <b>{{selectedNotification.organization_name}}</b>
                  </div>
                  <div  class="mb-50">
                    Transmission <b-icon-arrow-right-short variant="success"/> <b>{{selectedNotification.transmission_name}}</b>
                  </div>
                  <div  class="mb-50">
                    Event <b-icon-arrow-right-short variant="success"/> <b>{{selectedNotification.event_name}}</b>
                  </div>
                </h4>
              </div>
            </div>
            
            <div align="center">
              <div class="mb-1">
                <b-button variant="success" :to="{name: 'transmission-dashboard', params:{transmissionID:selectedNotification.transmission_id}}">
                  <span class="text-black font-weight-bolder">Access Transmission</span>
                </b-button>
              </div>
              <div class="text-secondary small font-weight-bold">
                Don't want to receive notifications like these?
                <div>
                  <b-link  :to="{name: 'transmission-info', params:{transmissionID:selectedNotification.transmission_id}}">Notification Management</b-link>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="!Array.isArray(notifications)">
            <div align="center">
              <div class="d-flex align-items-center mb-5" align="left">
                <b-skeleton width="80px" height="80px" type="avatar" class="mb-0 mr-2"/>
                <div>
                  <b-skeleton width="300px" height="40px"/>
                  <b-skeleton width="350px" height="20px" class="m-0"/>
                </div>
              </div>
              <div class="mb-4">
                <b-skeleton width="500px" height="20px"/>
                <b-skeleton width="400px" height="20px"/>
                <b-skeleton width="450px" height="20px"/>
              </div>
              
              <div class="">
                <b-skeleton width="250px" height="20px"/>
                <b-skeleton width="250px" height="20px"/>
                <b-skeleton width="250px" height="20px"/>
              </div>

            </div>

            <div align="center" class="pb-3">
              <b-skeleton width="200px" height="50px"/>
            </div>
          </template>

        </b-card>
    </b-col>
  </b-row>
  
</div>
</template>

<script>
import moment from 'moment'

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BBadge,
  BImg,
  BInputGroupText,
  BCollapse,
  BCard,
  BLink,
  BSpinner,
} from "bootstrap-vue";

import { mapGetters } from 'vuex';
import { errorToast } from '@/custom/class/FunctionClasses/CommonToasts';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BSkeleton,
    BBadge,
    BImg,
    BInputGroupText,
    BCollapse,
    BCard,
    BLink,
    BSpinner,
  },
  data() {
    return {
      selectedNotification: undefined,
      searchTerm: "",

      // notifications: undefined,
      // shown: undefined,
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    ...mapGetters('notification', ["getNotifications", "getMeta"]),
    notifications(){
      return this.getNotifications
    }
  }, 
  mounted () {
    this.fetchNotifications();
  },
  methods: {
    moment,
    fetchNotifications(){ 
      const user = this.getLoggedUser

      this.$store.dispatch('notification/fetchNotifications',{userId: user.userId, resetState: true})
        .then(async (resp)=>{
          await this.$nextTick()
          this.selectNotification(this.notifications[0])
        })
        .catch((err)=>{
          console.error(err)
          errorToast({text: "Couldn't fetch notifications"})
        })

    },
    selectNotification(notification){
      this.selectedNotification = notification


      if (notification && notification.visualization == false){
        this.$store.dispatch('notification/visualizeNotifications',{notificationIds:[notification.id]})        
      }
    },
    reachedListEnd(isEnd){

      if (isEnd == true){
        const meta = this.getMeta
        if (!meta.isLoading && meta.total && Array.isArray(this.notifications) && (meta.total > this.notifications.length)){
          const user = this.getLoggedUser
          this.$store.dispatch('notification/fetchNotifications', {userId: user.userId, resetState: false})
        }        
        
      }

    },
    searchNotifications(){
      let term = this.searchTerm
      const user = this.getLoggedUser
      this.$store.dispatch('notification/fetchNotifications',{userId: user.userId, resetState: true, searchTerm: term})
        .then(async (resp)=>{
          await this.$nextTick()
          this.selectNotification(this.notifications[0])
        })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.notification-list-item{
  //margin-bottom: 10px;
  position: relative;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 14px;
  transition: all 0.25s;
  border: 1px solid transparent;
  cursor: pointer;
  .notification-viewed-indicator{
    position: absolute;
    right: 15px;
  }

  &.was-viewed{
    opacity: 0.35;
  }
  &:hover{
    background-color: transparentize($light, 0.97);
  }
  &.is-shown{
    background-color: transparentize($success, 0.9);
    opacity: 1;
  }
  &:active{
    border-color: $success;
  }
}



.notification-alert-icon{
  @mixin coloring($variant){
    background-color: transparentize($variant, 0.7); 
    color: $variant;
  }
  padding: 0.6em 0.75em;
  border-radius: 50%;

  &.large{
    font-size: 30px;
  }
  
  &.warning{
    @include coloring($warning);
  }

  &.danger{
    @include coloring($danger);
  }
}

.notifications-card-height{
  $height: calc(100vh - 250px);
  height: $height;
  max-height: $height;
}
.flex-direction-column{
  flex-direction: column;
}
.notification-list-container{
  flex: 1;
  overflow-y: auto;
}
.notifications-number-sign{
  position: absolute;
  right: -8px;
  top: 0;
  font-size: 12px;
  transform: translateX(100%);
  border-bottom: 1px solid $success;
}
</style>